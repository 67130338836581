import React from "react";
import BackEndSkills from "./BackEndSkills";

const MainSection = () => {
  return (
    <div className="container mx-auto lg:h-[1000px] h-auto 2xl:h-[1500px] 2xl:pb-20">
      <div className="w-[100%] lg:px-14 py-16 px-4 flex flex-col justify-center text-center">
        <div className="flex items-center justify-center flex-col text-center">
          <h1 className="text-white text-center text-3xl my-4 font-bold">
            Professional Skills
          </h1>
          <hr className="border-[2px] border-[#00C6BF] w-[100px]" />
        </div>

        <div className=" lg:ml-16 mt-14 pb-8 justify-center items-center">
          <BackEndSkills />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
