import React from "react";
import { SiMysql, SiExpress, SiSanity, SiFirebase } from "react-icons/si";
import { DiNodejs } from "react-icons/di";
import { BiLogoMongodb } from "react-icons/bi";
import { FaGitAlt, FaFigma, FaReact } from "react-icons/fa";
import { TbBrandJavascript } from "react-icons/tb";
import { TiHtml5 } from "react-icons/ti";
import { MdCss } from "react-icons/md";
import { SiTailwindcss, SiTypescript, SiPostman } from "react-icons/si";
import { BsBootstrapFill } from "react-icons/bs";
import CommonContent from "./CommonContent";

const BackEndSkills = () => {
  const skills = [
    { icon: <TbBrandJavascript />, name: "JavaScript" },
    { icon: <SiTypescript />, name: "TypeScript" },
    { icon: <FaReact />, name: "React" },
    { icon: <DiNodejs />, name: "Node.js" },
    { icon: <SiExpress />, name: "Express" },
    { icon: <TiHtml5 />, name: "HTML5" },
    { icon: <MdCss />, name: "CSS3" },
    { icon: <SiTailwindcss />, name: "Tailwind CSS" },
    { icon: <BsBootstrapFill />, name: "Bootstrap" },
    { icon: <SiMysql />, name: "MySQL" },
    { icon: <BiLogoMongodb />, name: "MongoDB" },
    { icon: <SiSanity />, name: "Sanity" },
    { icon: <SiFirebase />, name: "Firebase" },
    { icon: <FaGitAlt />, name: "Git" },
    { icon: <FaFigma />, name: "Figma" },
    { icon: <SiPostman />, name: "Postman" },
  ];
  return (
    <div className=" text-white pt-4">
      <CommonContent skills={skills} />
    </div>
  );
};

export default BackEndSkills;
