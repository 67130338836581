import React from "react";

const CommonContent = ({ skills }) => {
  return (
    <div>
      <div className="text-7xl grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-8 gap-4 mt-4 justify-center items-center">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="my-3 border-2 lg:p-1 p-2 rounded border-[#5b6969] flex justify-center items-center lg:h-[120px]"
          >
            {skill.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonContent;
